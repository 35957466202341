import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import AppContext from "../../context/Context";
import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";

const TopNavRightSideNavItem = (props) => {
  const { isTopNav, companyID, companyData } = useContext(AppContext);

  const shortName = (name) => {
    if (name.length > 25) {
      return name.substring(0, 25) + "...";
    }
    return name;
  };

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {/*<NavItem>
        <SettingsAnimatedIcon />
      </NavItem>*/}
      {isTopNav && (
        <NavItem className="d-none d-md-block p-2 px-lg-0 cursor-pointer">
          <NavLink tag={Link} to="/changelog" id="changelog">
            <FontAwesomeIcon icon="code-branch" transform="right-6 grow-4" />
          </NavLink>
          <UncontrolledTooltip autohide={false} placement="left" target="changelog">
            Changelog
          </UncontrolledTooltip>
        </NavItem>
      )}
      {/* <CartNotification /> */}

      {window.innerWidth > 768 && (
        <div className="d-flex ">
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              color={
                companyData.plan == "Basico"
                  ? "secondary"
                  : companyData.plan == "Estandar"
                    ? "primary"
                    : "success"
              }
              className="mr-2"
            >
              {companyData && companyData.plan}
            </Badge>
          </div>
          <div className="mr-2 d-flex flex-column" translate="no">
            {companyData && shortName(companyData.companyName)}
            <br />
            <small>{companyID}</small>
          </div>
        </div>
      )}

      <NotificationDropdown />
      <ProfileDropdown name={props.name} />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
